<template>
	<div class="dflex-column container">
		<span class='title' style="margin-bottom: 3rem">品牌介绍</span>
		<span class="txt1">美乐童年是一个在线美术教育品牌，</span>
		<span class="txt1">专注打造为3-8岁全球儿童提供创造性思维AI课程的在线美术教育平台</span>
		<span class="txt2">中文名称：美乐童年</span>
		<span class="txt2" style="margin-bottom: 7.89rem">所属行业：美术教育</span>

		<span class='title' style="margin-bottom: 3.76rem">基本介绍</span>
		<span class="txt3">美乐童年专注于为3-8岁儿童提供在线美术教育课程，致力于在艺术活动中</span>
		<span class="txt3">培养孩子的创造性思维，课程采用真人老师加AI的在线趣味互动，专属社群协</span>
		<span class="txt3">助课后辅导，激发并保持孩子的参与兴趣，致力于提高孩子的综合素养能力。</span>
		<span class="txt3">根据不同类型的孩子，提供差异化培养方案，致力于满足儿童美术培养的多样</span>
		<span class="txt3" style="margin-bottom: 9.10rem">化需求。</span>

		<span class='title' style="margin-bottom: 5.11rem">课程模块</span>
		<span class="title2">经典美学</span>
		<span class="txt4" style="margin-bottom: 4.36rem">系统学习美术表现手法，包含水粉、水彩、水墨到漫画、设计、手作等等</span>
		<span class="title2">大师美学</span>
		<span class="txt4" style="margin-bottom: 5.79rem">致力于了解中外美学大师，领略多元文化，旨在提升孩子美学素养。</span>
		<span class="title2">创意美学</span>
		<span class="txt4" style="margin-bottom: 4.36rem">拓展孩子的思考维度，致力于让孩子享受绘画的过程，使作品正真充满想象力，彰显与众不同。</span>
		<span class="title2">STEAM美学</span>
		<span class="txt4" style="margin-bottom: 8.27rem">用艺术的眼光，探索太空、海洋、生物、物理、化学等领域，通过观察、想像、联想、分析等途径，培养综合跨界力。</span>

		<span class='title' style="margin-bottom: 2.63rem">美术核心素养</span>
		<span class="txt5">艺术思维、图像识图、美术表现、审美判断、创意实践和文化理解。</span>

		<span class='title' style="margin-bottom: 1.80rem">品牌愿景</span>
		<span class="txt5">成为孩子更爱画画的地方。</span>

		<span class='title' style="margin-bottom: 2.48rem">品牌理念</span>
		<span class="txt5">艺术点亮思维，启蒙赢得未来。</span>

		<span class='title' style="margin-bottom: 2.18rem">品牌目标</span>
		<span class="txt5" style="margin-bottom: 0.83rem">美乐童年通过对儿童艺术发展数据的深度分析，</span>
		<span class="txt5">优化适合中国儿童美育发展的课程产品，并且致力于打造儿童线上美育领域的专业地位。</span>
	</div>
</template>

<script>
	export default {

	}
</script>

<style lang="scss" scoped>
	.container {
		margin-top: 5rem;
		padding: 12rem 0 0 32rem;
		.title {
			opacity: 1;
			font-size: 5rem;
			// font-family: PingFang, PingFang-SC;
			font-weight: bold;
			color: #25222d;
		}
		.title2 {
			opacity: 1;
			font-size: 3rem;
			// font-family: PingFangSC, PingFangSC-Medium;
			font-weight: 600;
			color: #514e57;
			margin-bottom: 3rem;
		}
		.txt1 {
			opacity: 1;
			font-size: 1.17rem;
			// font-family: PingFangSC, PingFangSC-Medium;
			font-weight: 600;
			color: #5c5c5c;
			margin-bottom: 2.18rem;
		}
		.txt2 {
			opacity: 1;
			font-size: 1.17rem;
			// font-family: PingFangSC, PingFangSC-Medium;
			font-weight: 600;
			color: #555555;
			margin-bottom: 1rem;
		}
		.txt3 {
			opacity: 1;
			font-size: 1.17rem;
			// font-family: PingFangSC, PingFangSC-Medium;
			font-weight: 600;
			color: #514e57;
			margin-bottom: 0.83rem
		}
		.txt4 {
			opacity: 1;
			font-size: 2rem;
			// font-family: PingFangSC, PingFangSC-Medium;
			font-weight: 600;
			color: #514e57;
			letter-spacing: 0rem;
		}
		.txt5 {
			opacity: 1;
			font-size: 2rem;
			// font-family: PingFangSC, PingFangSC-Medium;
			font-weight: 600;
			color: #514e57;
			letter-spacing: 0rem;
			margin-bottom: 8.50rem
		}
	}
</style>