<template>
	<div>
		<div class="row1 dflex">
			<div class="dflex-column col1">
				<!-- <div class="header">
					<img src="@/assets/img/logo@2x.png" alt="" class="row1Img1">
				</div> -->
				<img src="@/assets/img/index_title_1@2x.png" alt="" class="row1Img3">
				<div class="blueLine line"></div>
				<span class="txt">
					美乐童年出品，专为3-8岁儿童研发
				</span>
				<span class="txt txt2">
					是为3-8岁全球儿童提供艺术思维AI课程的在线美术教育平台。
				</span>
			</div>
			<img src="@/assets/img/index_img_1@2x.png" alt="" class="row1Img2">
		</div>

		<div class="row2 dflex-column">
			<div class="title">为什么大家都选美乐童年？</div>
			<div class="dflex iconBox">
				<div class='dflex-column item'>
					<div class="dflex circle">
						<img src="@/assets/img/index_class@2x.png" alt="" class="iconImg">
					</div>
					<span class="txt1">在线AI课程+动画故事</span>
					<span class="txt2">互动性+趣味性</span>
				</div>
				<div class='dflex-column item'>
					<div class="dflex circle">
						<img src="@/assets/img/class_flag@2x.png" alt="" class="iconImg">
					</div>
					<span class="txt1">央美教授+专业团队</span>
					<span class="txt2">权威性+专业性</span>
				</div>
				<div class='dflex-column item'>
					<div class="dflex circle">
						<img src="@/assets/img/index_test@2x.png" alt="" class="iconImg">
					</div>
					<span class="txt1">课后点评+阶段测评</span>
					<span class="txt2">直观性+全面性</span>
				</div>
				<div class='dflex-column item'>
					<div class="dflex circle">
						<img src="@/assets/img/index_draw@2x.png" alt="" class="iconImg">
					</div>
					<span class="txt1">配套教材+品牌画材</span>
					<span class="txt2">安全性+环保性</span>
				</div>
			</div>
		</div>

		<div class="row3 dflex">
			<img src="@/assets/img/index_img_2@2x.png" alt="" class="row3Img1">
			<div class="dflex-column col2">
				<img src="@/assets/img/index_title_2@2x.png" alt="" class="row3Img2">
				<span class="txt1">在线趣味AI课</span>
				<div class="blueLine line"></div>
				<span class="txt2">实时在线互动</span>
				<span class="txt3">教学环节更精细</span>
			</div>
		</div>

		<div class="row4 dflex">
			<div class="dflex-column col1">
				<img src="@/assets/img/index_title_3@2x.png" alt="" class="row4Img3">
				<div class="item">
					<img src="@/assets/img/index_icon_flag@2x.png" alt="" class="row4Img1">
					<span class="txt">百科知识 扩展认知</span>
				</div>
				<div class="item">
					<img src="@/assets/img/index_icon_flag@2x.png" alt="" class="row4Img1">
					<span class="txt">情节巧妙 促进思维</span>
				</div>
				<div class="item">
					<img src="@/assets/img/index_icon_flag@2x.png" alt="" class="row4Img1">
					<span class="txt">寓意丰富 发展品格</span>
				</div>
				<div class="item">
					<img src="@/assets/img/index_icon_flag@2x.png" alt="" class="row4Img1">
					<span class="txt">精美画风 提升审美</span>
				</div>
			</div>
			<img src="@/assets/img/index_img_3@2x.png" class="row4Img2">
		</div>

		<div class="dflex-column row5">
			<img src="@/assets/img/index_title_4@2x.png" alt="" class="row5Img1">
			<source-list class="sourceList"></source-list>
		</div>

		<div class="row6 dflex-column">
			<img src="@/assets/img/index_title_5@2x.png" alt="" class="row6Img2">
			<span class="txt">全系美乐童年画材教具  丰富有趣创意随材</span>
			<img src="@/assets/img/index_img_5@2x.png" alt="" class="row6Img1">
		</div>

	</div>
</template>

<script>
	import sourceList from '@/components/index/sourceList.vue'
	export default {
		data() {
			return {

			}
		},
		components: {
			sourceList
		}
	}
</script>

<style lang="scss" scoped>
	// 蓝色短线
	.blueLine {
		width: 1.5rem;
		height: 0.42rem;
		background: #36ACFD;
	}

	.row1 {
		height: 50.33rem;
		.col1 {
			margin-left: 32.08rem;
			.line {
				margin-top: 5.5rem;
				margin-bottom: 5.5rem;
			}
			.txt {
				font-size: 1.17rem;
				font-weight: 500;
				color: #514E57;
				font-family: PingFang-SC-Medium, PingFang-SC;
			}
			.txt2 {
				margin-top: 1.7rem;
			}
		}
		.row1Img2 {
			width: 57.33rem;
			height: 43.33rem;
			align-self: flex-end;
			margin-left: 2rem;
		}
		.row1Img3 {
			width: 36rem;
			height: 12.5rem;
			margin-top: 12rem;
		}
	}

	.row2 {
		height: 63.17rem;
		background: #36ACFD;
		border-radius: 0.17rem;
		.title {
			text-align: center;
			margin-top: 10rem;
			font-size: 3.33rem;
			font-weight: 500;
			color: #FFFFFF;
			font-family: PingFangSC-Medium, PingFang SC;
		}
		.iconBox {
			justify-content: center;
			margin-top: 11.67rem;
			.item {
				width: 26rem;
				align-items: center;
				.circle {
					width: 10rem;
					height: 10rem;
					background: #fff;
					border-radius: 50%;
					margin-bottom: 2.5rem;
					&:hover {
						box-shadow: 0rem 0.83rem 3.33rem 0rem #1D8BF7;
					}
					.iconImg {
						width: 6rem;
						height: 6rem;
						margin: auto;
					}
				}
				.txt1 {
					font-size: 1.67rem;
					font-weight: 500;
					color: #FFFFFF;
					font-family: PingFang-SC-Medium, PingFang-SC;
				}
				.txt2 {
					margin-top: 0.2rem;
					font-size: 1.17rem;
					font-weight: 400;
					color: #FFFFFF;
					font-family: PingFang-SC-Regular, PingFang-SC;
				}
			}

		}
	}

	.row3 {
		height: 76.67rem;
		.row3Img1 {
			width: 49rem;
			height: 65rem;
			align-self: flex-end;
			margin-left: 32.08rem;
		}

		.col2 {
			align-items: flex-end;
			.row3Img2 {
				width: 42.92rem;
				height: 12.08rem;
				margin-left: 3.92rem;
				margin-top: 16.67rem;
			}
			.txt1 {
				margin-top: 5.83rem;
				font-size: 4.08rem;
				font-weight: 500;
				color: #25222D;
				font-family: PingFang-SC-Medium, PingFang-SC;
			}
			.line {
				margin-top: 5rem;
				margin-bottom: 5rem;
			}
			.txt2 {
				font-size: 1.5rem;
				font-weight: 500;
				color: #514E57;
				font-family: PingFang-SC-Medium, PingFang-SC;
			}
			.txt3 {
				@extend .txt2;
				margin-top: 0.5rem;
			}
		}
	}

	.row4 {
		height: 81.08rem;
		background: #FAFAFA;
		.col1 {
			margin-top: 22.5rem;
			margin-left: 32.17rem;
			.item {
				margin-bottom: 2.08rem;
				display: flex;
				.row4Img1 {
					width: 3.67rem;
					height: 3.83rem;
				}
				.txt {
					font-size: 1.5rem;
					font-weight: 500;
					color: #25222D;
					margin-left: 1.67rem;
					align-self: center;
					font-family: PingFangSC-Medium, PingFang SC;
				}
			}

			.row4Img3 {
				width: 34.67rem;
				height: 12rem;
				margin-bottom: 5rem;
			}
		}
		.row4Img2 {
			width: 58.33rem;
			height: 41.67rem;
			margin-top: 21.25rem;
			margin-left: 2.83rem;
		}
	}

	.row5 {
		height: 142.74rem;
		.row5Img1 {
			width: 34.58rem;
			height: 4.83rem;
			align-self: center;
			margin-top: 10rem;
		}
		.courseList {
			align-self: center;
		}
	}

	.row6 {
		height: 106.67rem;
		background: #FAFAFA;
		align-items: center;
		.row6Img1 {
			width: 87.5rem;
			height: 58.33rem;
		}
		.row6Img2 {
			width: 29.67rem;
			height: 12rem;
			margin-top: 10rem;
			margin-bottom: 3.33rem;
		}
		.txt {
			font-size: 1.67rem;
			font-weight: 500;
			color: #25222D;
			font-family: PingFang-SC-Medium, PingFang-SC;
		}
	}


</style>