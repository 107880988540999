<template>
	<div class="courseList">
				<div class="row1 dflex-column">
					<div class="row1 dflex">
						<div class="col1 btn1 flex-end">年龄级别</div>
						<div class="col2 btn3 flex-end">L1</div>
						<div class="col3 btn7 flex-end">L2</div>
						<div class="col4 btn11">L3</div>
					</div>
					<div class="row2 dflex">
						<div class="col1 btn1">推荐年龄</div>
						<div class="col2 btn3">3+</div>
						<div class="col3 btn8">5+</div>
						<div class="col4 btn12">7+</div>
					</div>
					<div class="row3 dflex">
						<div class="col1 btn1">成长阶段</div>
						<div class="col2 btn3">涂鸦期</div>
						<div class="col3 btn8">象征期</div>
						<div class="col4 btn12">图示期</div>
					</div>
					<div class="row4 dflex">
						<div class="col1 btn2">艺术思维</div>
						<div class="col2 dflex-column btn4">
							<span>联想思维</span>
							<span>操作思维</span>
							<span>动态思维</span>
							<span>目标思维</span>
						</div>
						<div class="col3 dflex-column btn9">
							<span>主题发散思维</span>
							<span>场景发散思维</span>
							<span>故事发散思维</span>
							<span>情感发散思维</span>
						</div>
						<div class="col4 dflex-column btn13">
							<span>表现思维</span>
							<span>象征思维</span>
							<span>置换思维</span>
							<span>空间思维</span>
							<span>逻辑思维</span>
							<span>启发思维</span>
						</div>
					</div>
					<div class="row5 dflex">
						<div class="col1 btn6">课程理念</div>
						<div class="col2 dflex-column btn5">
							<span>艺术发现</span>
							<span class="plh1">占位</span>
							<span>· 着重培养表达力、逻</span>
							<span>辑力、审美力</span>
							<span class="plh1">占位</span>
							<span>· 造型特征表现，锻炼</span>
							<span>思维逻辑帮助孩子收获</span>
							<span>色彩敏感期的惊喜</span>
						</div>
						<div class="col3 dflex-column btn10">
							<span>想象创造</span>
							<span class="plh2">占位</span>
							<span>· 着重培养表达力、逻</span>
							<span>辑力、审美力、创造力</span>
							<span class="plh2">占位</span>
							<span>· 加强细节观察，用想</span>
							<span>象创造表现</span>
						</div>
						<div class="col4 dflex-column btn14">
							<span>艺术设计</span>
							<span class="plh3">占位</span>
							<span>· 着重培养逻辑力、审</span>
							<span>美力、创造力</span>
							<span class="plh3">占位</span>
							<span>· 加入设计概念， 从</span>
							<span>实际出发提高表现、鉴</span>
							<span>赏和实际应用能力</span>
						</div>
					</div>
				</div>
				<div class="row2 dflex">
					<div class="col1 btn1">课程内容</div>
					<div class="col2 btn16">经典美学 、 大师美学 、 创意美学 、 STEAM美学</div>
				</div>
				<div class="row3 dflex">
					<div class="col1 btn1">美术核心素养</div>
					<div class="col2 btn16">艺术思维、图像识图、美术表现、审美判断、创意实践和文化理解</div>
				</div>
			</div>
</template>

<script>
	export default {
		
	}
</script>

<style lang="scss" scoped>
	.size1 {
		width: 23.58rem;
		height: 9rem;
		border-radius: 1.5rem;
	}
	.size2 {
		width: 23.58rem;
		height: 12.33rem;
		border-radius: 1.5rem;
	}
	.size3 {
		width: 23.58rem;
		height: 15.67rem;
		border-radius: 1.5rem;
	}
	.size4 {
		width: 23.58rem;
		height: 21.67rem;
		border-radius: 1.5rem;
	}
	.size5 {
		width: 71.75rem;
		height: 9rem;
		border-radius: 1.5rem;
	}
	.size6 {
		width: 23.58rem;
		height: 25.42rem;
		border-radius: 1.5rem;
	}

	.bg1 {
		background: #F7F7F7;
	}
	.bg2 {
		background: #36ACFD;
		opacity: 0.6;
	}
	.bg3 {
		background: #36ACFD;
		opacity: 0.8;
	}
	.bg4 {
		background: #36ACFD;
	}
	.bg5 {
		background: linear-gradient(90deg, rgba(52, 172, 253, 0.6) 0%, #36ACFD 100%);
	}
	.plh1 {
		color: rgba(54, 172, 253, 0.6);
		line-height: 1.5rem;
	}
	.plh2 {
		color: rgba(54, 172, 253, 0.8);
		line-height: 1.5rem;
	}
	.plh3 {
		color: rgba(54, 172, 253, 1);
		line-height: 1.5rem;
	}
	.txt1 {
		font-size: 1.67rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #25222D;
		text-align: center;
	}
	.txt2 {
		font-size: 1.67rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		text-align: center;
	}
	.margin {
		margin-bottom: 0.5rem;
		margin-right: 0.5rem;
	}
	.btn1 {
		@extend .size1,.bg1,.txt1,.margin;
		line-height: 9rem;
	}
	.btn2 {
		@extend .size4,.bg1,.txt1,.margin;
		line-height: 21.67rem;
	}
	.btn3 {
		@extend .size1,.bg2,.txt2,.margin;
		line-height: 9rem;
	}
	.btn4 {
		@extend .size4,.bg2,.txt2,.margin;
		justify-content: center;
	}
	.btn5 {
		@extend .size6,.bg2,.txt2,.margin;
		justify-content: center;
	}
	.btn6 {
		@extend .size6,.bg1,.txt1,.margin;
		line-height: 25.42rem;
	}
	.btn7 {
		@extend .size2,.bg3,.txt2,.margin;
		line-height: 12.33rem;
	}
	.btn8 {
		@extend .size1,.bg3,.txt2,.margin;
		line-height: 9rem;
	}
	.btn9 {
		@extend .size4,.bg3,.txt2,.margin;
		justify-content: center;
	}
	.btn10 {
		@extend .size6,.bg3,.txt2,.margin;
		justify-content: center;
	}
	.btn11 {
		@extend .size3,.bg4,.txt2,.margin;
		line-height: 15.67rem;
		margin-top: 6.67rem;
	}
	.btn12 {
		@extend .size1,.bg4,.txt2;
		line-height: 9rem;
	}
	.btn13 {
		@extend .size4,.bg4,.txt2;
		justify-content: center;
	}
	.btn14 {
		@extend .size6,.bg4,.txt2;
		justify-content: center;
	}
	.btn16 {
		@extend .size5,.bg5,.txt2;
		line-height: 9rem;
	}
	.flex-end {
		align-self: flex-end;
	}
</style>