<template>
	<div class="dflex container">
		<div class="dflex-column col1">
			<img src="@/assets/img/title@3x.png" alt="" class="titleImg">
			<div class="dflex-column tips">
				<span>艺术点亮思维</span>
				<span>宝盒启智天赋</span>
			</div>
			<div class="dflex-center qrBox">
				<img src="@/assets/img/QRCode.png" class="QRCode">
			</div>
			<div class="dflex-center downloadBtn">立即微信扫码下载</div>
		</div>
		<img src="@/assets/img/appShow.png" alt="" class="appShowImg">
	</div>
</template>

<script>
	export default {
		
	}
</script>

<style lang="scss" scoped>
	.container {
		margin-top: 5rem;
		.col1 {
			margin-left: 34rem;
			.titleImg {
				width: 29rem;
				height: 4rem;
				margin-top: 24rem;
			}
			.tips {
				opacity: 0.5;
				font-size: 2rem;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				color: #153245;
				margin-top: 3rem;
				margin-bottom: 7rem;
			}
			.qrBox {
				width: 15rem;
				height: 15rem;
				background: rgba(0,134,255,0.10);
				border: 0.17rem solid rgba(0,133,255,0.20);
				border-radius: 1rem;
				margin-bottom: 2rem;
				.QRCode {
					width: 13rem;
					height: 13rem;
					border-radius: 1rem;
				}
			}
			.downloadBtn {
				width: 15rem;
				height: 3rem;
				background: #0086ff;
				border-radius: 2rem;
				font-size: 1rem;
				font-family: PingFangSC, PingFangSC-Medium;
				font-weight: 500;
				color: #ffffff;
			}
		}
		
		.appShowImg {
			width: 63rem;
			height: 79rem;
			margin-left: 3rem;
			margin-top: 6rem;
		}
	}
</style>