<template>
	<div class="container">
		<!-- 头部 -->
		<div class="header dflex-space">
			<img src="../assets/img/logo@3x.png" alt="" class="headerImg">
			<!-- 导航 -->
			<div class="dflex tabs">
				<div class="dflex-column item" @click='switchTab(0)'>
					<span :class="currentTab===0?'titleActive':'title'">首页</span>
					<div class="line" v-if="currentTab===0"></div>
				</div>

				<div class="dflex-column item" @click='switchTab(1)'>
					<span :class="currentTab===1?'titleActive':'title'">品牌介绍</span>
					<div class="line" v-if="currentTab===1"></div>
				</div>

				<div class="dflex-column item" @click='switchTab(2)'>
					<span :class="currentTab===2?'titleActive':'title'">下载APP</span>
					<div class="line" v-if="currentTab===2"></div>
				</div>
			</div>
		</div>
		<!-- 主体区域 -->
		<!-- 首页 -->
		<home v-if="currentTab===0"/>
		<!-- 品牌介绍 -->
		<brand-intro v-else-if="currentTab===1"/>
		<!-- 下载页 -->
		<download v-else-if="currentTab===2"/>

		<!-- 底部 -->
		<div class="dflex-column row7">
				<span class="txt">Copyright © 2023 宁波美乐童年玩具有限公司</span>
				<div class="dflex">
					<a class="txt" href="https://beian.miit.gov.cn/" target="view_window">浙ICP备2023001138号-1</a>
					<img style="margin: 0 0px 0 10px;" src="@/assets/img/beianIcon.png" alt="">
					<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?arecordcode=33021202001652" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">浙公网安备 33021202001652号</p></a>
				</div>
		</div>
	</div>
</template>

<script>
	import home from './home/home'
	import download from './download/download'
	import brandIntro from './brandIntro/brandIntro'
	export default {
		components: {
			home,
			download,
			brandIntro
		},
		data() {
			return {
				currentTab: 0, // 当前激活项
			}
		},
		created() {
			if(this.$route.params.whichPage) {
				const tempArr = ['index', 'brand', 'download']
				this.currentTab = tempArr.indexOf(this.$route.params.whichPage)
			}
		},
		methods: {
			// 切换导航栏
			switchTab(index) {
				this.currentTab = index
			}
		},
	}
</script>

<style lang="scss" scoped>
	// 蓝色短线
	.blueLine {
		width: 1.5rem;
		height: 0.42rem;
		background: #36ACFD;
	}
	.container {
		.header {
			position: fixed;
			top: 0;
			background: #fff;
			width: 100%;
			height: 5rem;
			z-index: 2;
			.headerImg {
				width: 15rem;
				height: 4rem;
				margin-top: 1rem;
				margin-left: 32rem;
			}
		.tabs {
			.item {
				width: 8rem;
				align-items: center;
        cursor: pointer;
				.title {
					opacity: 0.5;
					font-size: 1rem;
					font-family: PingFangSC, PingFangSC-Regular;
					font-weight: 400;
					color: #153245;
					margin-top: 2rem;
				}
				.titleActive {
					opacity: 1;
					font-size: 1rem;
					font-family: PingFangSC, PingFangSC-Regular;
					font-weight: 400;
					color: #153245;
					margin-top: 2rem;
				}
				.line {
					width: 3rem;
					height: 3.5px;
					opacity: 1;
					background: #0086ff;
					border-radius: 2px;
					margin-top: 1rem;
				}
			}
			.item:last-child {
				margin-right: 38rem;
			}
		}
		}

		.row7 {
			height: 5.42rem;
			// line-height: 5.42rem;
			background: #06080A;
			text-align: center;
			justify-content: center;
			align-items: center;
			.txt {
				color: rgba(255, 255, 255, 0.65);
				font-size: 1.17rem;
				font-weight: 400;
				font-family: PingFangSC-Regular, PingFang SC;
			}
		}
	}
</style>