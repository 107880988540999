<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  methods: {
    
  }
}
</script>

<style lang="scss">
  * {
    margin: 0;
    padding: 0;
  }
  html,body,#app{height:100%;}

  /*  -- flex弹性布局 -- */
  .dflex {
    display: flex;
    display:-webkit-flex;
  }
  .dflex-column {
    display: flex;
    display:-webkit-flex;
    flex-direction: column;
  }
  .dflex-space {
    display: flex;
    display:-webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
  }
  .dflex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  // @font-face
  // {
  // font-family: PingFang-SC-Medium;
  // src: url('./assets/fonts/PingFang Medium.ttf');
  // }
  // @font-face
  // {
  // font-family: PingFang-SC-Regular;
  // src: url('./assets/fonts/PingFang Regular.ttf');
  // }

  span {
    font-family: PingFang-SC-Medium,PingFang-SC-Regular;
  }
</style>
